import styles from './MainFooterExpandableLegalInfo.module.scss';
import Typography from '#components/shared/ui/Typography';
import { useState } from 'react';

type Props = {
  info: string;
};

const MainFooterExpandableLegalInfo = ({ info }: Props) => {
  const [collapsed, setCollapsed] = useState<boolean>(true);

  return (
    <Typography
      component="p"
      variant="capture"
      color="lightGrey"
      className={collapsed ? styles.collapsed : ''}
      onClick={() => setCollapsed((collapsed) => !collapsed)}
    >
      {info}
    </Typography>
  );
};

export default MainFooterExpandableLegalInfo;
